import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BlockCarouselQuery } from "../../fragments/NodeBlockCarouselFields";
import Slider from "react-slick";
import ImageCopy from "./ImageCopy";
import { colors } from "../ui/colors";

const SlickStylingWrap = styled.div<{height: number}>`
  .slick-list {
    z-index: 0;
    // height: 100%;
  }

  // .slick-track
  // {
  //     display: flex !important;
  // }
  
  // .slick-slide
  // {
  //     height: auto !important;
  // }

  /* Slider */
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }

  /* Icons */
  @font-face {
    font-family: "slick";
    font-weight: normal;
    font-style: normal;

    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"),
      url("./fonts/slick.woff") format("woff"),
      url("./fonts/slick.ttf") format("truetype"),
      url("./fonts/slick.svg#slick") format("svg");
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 50px;
    height: 50px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 50px;
    line-height: 1;

    opacity: 0.75;
    color: white;
    z-index: 2;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 20px;
    z-index: 2;
  }
  [dir="rtl"] .slick-prev {
    right: 25px;
    left: auto;
  }
  .slick-prev:before {
    content: "❮";
  }
  [dir="rtl"] .slick-prev:before {
    content: "❯";
  }

  .slick-next {
    right: 20px;
    z-index: 2;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: 25px;
  }
  .slick-next:before {
    content: "❯";
  }
  [dir="rtl"] .slick-next:before {
    content: "❮";
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: 50px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 16px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: "•";
    text-align: center;

    opacity: 0.25;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white;
  }
  @media only screen and (max-width: 767px) {
    .slick-dots {
      bottom: 10px;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }
`;

const CarouselWrap = styled.div`
  background: ${colors.black.getHex()};
`;

const SlickStyleWrapper = styled.div``;

const Carousel = ({ carouselData }: { carouselData: BlockCarouselQuery }) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [imageDimentions, setImageDimentions] = useState({
    height: 0,
    width: 0
  });

  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const current = carouselRef.current;
    const rect = current ? current.getBoundingClientRect() : null;
    if (rect) {
      setImageDimentions({ height: rect.height, width: rect.width });
    }
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const isMobileSize = viewWidth < 767;

  const settings = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <>
      <CarouselWrap>
        <SlickStylingWrap ref={carouselRef} height={imageDimentions.height}>
          {Array.isArray(carouselData.imageCopyBlocks) ? (
            <Slider {...settings}>
              {carouselData.imageCopyBlocks.map((imageCopySlide, i) => {
                return (
                  <div
                    style={{ height: "500px" }}
                    key={imageCopySlide.header + i}>
                    <ImageCopy
                      imageCopyData={imageCopySlide}
                      id="our-products"
                      inCarousel
                    />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <></>
          )}
        </SlickStylingWrap>
      </CarouselWrap>
    </>
  );
};

export default Carousel;
