import * as React from "react";
import { graphql } from "gatsby";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { WmkSeo } from "wmk-seo";
import { BlockImageCopyQuery } from "../fragments/NodeBlockImageCopyFields";
import { BlockCarouselQuery } from "../fragments/NodeBlockCarouselFields";
import { BlockContactQuery } from "../fragments/NodeContactFields";
import ImageCopy from "../components/Blocks/ImageCopy";
import Carousel from "../components/Blocks/Carousel";
import ContactForm from "../components/Blocks/Contact";

const IndexPage = ({ data }: { data: IndexPageQuery }) => (
  <>
    <WmkSeo.Meta
      title={"Home"}
      siteTitle={data.site.siteMetadata.title}
      baseUrl={data.site.siteMetadata.baseUrl}
      slug="/"
    />
    <ImageCopy imageCopyData={data.about} id="about"/>
    <Carousel carouselData={data.carouselData} />
    <ContactForm contactData={data.contact} />
  </>
);

export default IndexPage;

interface IndexPageQuery {
  site: SiteMetaDataFields;
  about: BlockImageCopyQuery;
  carouselData: BlockCarouselQuery;
  contact: BlockContactQuery;
}

export const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    about: contentfulBlockImageCopy(contentfulid: { eq: "About" }) {
      ...NodeBlockImageCopyFields
    }
    carouselData: contentfulBlockCarousel(contentfulid: { eq: "Schema" }) {
      ...NodeBlockCarouselFields
    }
    contact: contentfulBlockContact(contentfulid: { eq: "Schema" }) {
      ...NodeBlockContactFields
    }
  }
`;
