import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "../ui/Typography";
import HubspotForm from "react-hubspot-form";
import { BlockContactQuery } from "../../fragments/NodeContactFields";
// import GoogleMapReact from "google-map-react";
// import { useState, useEffect, useRef } from "react";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { colors } from "../ui/colors";

const FormContainer = styled(Container)`
  background: ${colors.black.getHex()};
  width: 100%;
  color: ${colors.white.getHex()};
  .col {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    padding: 1rem 10vw;
  }
  @media only screen and (max-width: 455px) {
    padding: 1rem;
  }
`;

const ContactContainer = styled(Container)`
  position: relative;
  .link-target {
    position: relative;
    top: -150px;
  }
  .form-col {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    .col-12 {
      min-height: 50vh;
    }
  }
`;

const ContactForm = ({ contactData }: { contactData: BlockContactQuery }) => {
  // const mapDefaults = {
  //   center: {
  //     lat: 41.20585,
  //     lng: -85.719
  //   },
  //   zoom: 15
  // };
  // const [zoomLevel, setZoomLevel] = useState(mapDefaults.zoom);
  // const lat = mapDefaults.center.lat;
  // const lng = mapDefaults.center.lng;
  return (
    <>
      <ContactContainer fluid>
        <div className="link-target" id="contact" />
        <Row>
          <Col xs={12} md={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <iframe
              width="100%"
              height="100%"
              frameborder="0"
              style={{border: 0}}
              referrerpolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA8udn4KHIaoRpctQ5JMI4UnX8iqbCtiwk&q=2+Arnolt+Dr.+Pierceton,+IN+46562&zoom=15"
              allowfullscreen></iframe>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: `AIzaSyA8udn4KHIaoRpctQ5JMI4UnX8iqbCtiwk`
              }}
              defaultCenter={mapDefaults.center}
              defaultZoom={mapDefaults.zoom}
              onChange={(map) => {
                const zoom = map.zoom;
                setZoomLevel(zoom);
              }}
              options={{
                styles: [
                  {
                    featureType: "all",
                    elementType: "all",
                    stylers: [
                      {
                        hue: "#008eff"
                      }
                    ]
                  },
                  {
                    featureType: "poi",
                    elementType: "all",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                  {
                    featureType: "road",
                    elementType: "all",
                    stylers: [
                      {
                        saturation: "0"
                      },
                      {
                        lightness: "0"
                      }
                    ]
                  },
                  {
                    featureType: "transit",
                    elementType: "all",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                  {
                    featureType: "water",
                    elementType: "all",
                    stylers: [
                      {
                        visibility: "simplified"
                      },
                      {
                        saturation: "-60"
                      },
                      {
                        lightness: "-20"
                      }
                    ]
                  }
                ]
              }}>
              <div
                lat={lat}
                lng={lng}
                zoom={zoomLevel}
                style={{
                  position: "absolute",
                  width: 50,
                  height: 50,
                  left: -25,
                  top: -60,
                  fontSize: 50
                }}>
                <FaMapMarkerAlt />
              </div>
            </GoogleMapReact> */}
          </Col>
          <Col xs={12} md={5} className="form-col">
            <FormContainer fluid>
              <Row>
                <Col>
                  <Typography.H2>{contactData.header}</Typography.H2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HubspotForm
                    portalId={"44365477"}
                    formId={"6e2abaf0-8012-43a9-9ada-af8041c4d37c"}
                  />
                </Col>
              </Row>
            </FormContainer>
          </Col>
        </Row>
      </ContactContainer>
    </>
  );
};

export default ContactForm;
